import * as React from "react";
import Layout from "../components/Layout";
import Contact from "../components/Contact";
import CTA from "../components/CTA";
import "./price.sass";

const ContactPage = () => {
  return (
    <Layout>
      <Contact></Contact>
      <CTA></CTA>
    </Layout>
  );
};

export default ContactPage;
