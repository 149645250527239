import * as React from "react";
import "./Contact.sass";
import office from "../images/office.png";
import { useState } from "react";

const Contact = () => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [message, setMessage] = useState("");
  const [promptName, setPromptName] = useState("");
  const [promptMail, setPromptMail] = useState("");
  const [promptMessage, setPromptMessage] = useState("");

  const encode = (data) => {
    const formData = new FormData();
    for (const key of Object.keys(data)) {
      formData.append(key, data[key]);
    }
    return formData;
  };

  const clearForm = () => {
    setName("");
    setMail("");
    setMessage("");
  };

  const clearPrompts = () => {
    setPromptName("");
    setPromptMail("");
    setPromptMessage("");
  };

  const promptTime = 2000;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;

    if (name.length < 2) {
      setPromptName("Veuillez saisir un nom valide");
      setTimeout(clearPrompts, promptTime);
      return;
    }

    if (mail.length < 5) {
      setPromptMail("Veuillez saisir un email valide");
      setTimeout(clearPrompts, promptTime);
      return;
    }

    if (message.length < 8) {
      setPromptMessage("Veuillez saisir un message");
      setTimeout(clearPrompts, promptTime);
      return;
    }

    let data = {
      person: name,
      email: mail,
      text: message,
    };

    try {
      await fetch("/", {
        method: "POST",
        body: encode({
          "form-name": form.getAttribute("name"),
          ...data,
        }),
      });
      console.log("Form posted:");
      console.log({
        "form-name": form.getAttribute("name"),
        ...data,
      });
      clearForm();
    } catch (err) {}
  };

  return (
    <section id="contact">
      <div id="top">
        <img src={office} alt="weely office"></img>
        <div id="form">
          <div id="form-title">
            <h2>Contactez-nous</h2>
            <p>
              Vous avez une question ou besoin d’un conseil ? Contactez-nous !
            </p>
          </div>
          <form
            name="together"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <div id="inputs">
              <input type="hidden" name="form-name" value="together" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" />
                </label>
              </p>
              <label for="nom">Nom</label>
              <label for="nom" className="prompt-label">
                {promptName}
              </label>
              <input
                type="text"
                id="nom"
                name="person"
                value={name}
                className={promptName.length === 0 ? "" : "error"}
                onChange={(v) => setName(v.target.value)}
              ></input>
              <label for="mail">Email</label>
              <label for="mail" className="prompt-label">
                {promptMail}
              </label>
              <input
                type="text"
                id="mail"
                name="email"
                value={mail}
                className={promptMail.length === 0 ? "" : "error"}
                onChange={(v) => setMail(v.target.value)}
              ></input>
              <label for="message">Message</label>
              <label for="message" className="prompt-label">
                {promptMessage}
              </label>
              <textarea
                id="message"
                name="text"
                value={message}
                className={promptMessage.length === 0 ? "" : "error"}
                onChange={(v) => setMessage(v.target.value)}
              ></textarea>
              <input type="submit" id="send-button" value="Envoyer" />
            </div>
          </form>
        </div>
      </div>
      <div id="countries">
        <div className="country-block" id="ch">
          <h3>Suisse</h3>
          <p>+41779454175</p>
          <p>spin@weely.ch</p>
        </div>
        <div className="country-block" id="fr">
          <h3>France</h3>
          <p>+33678414995</p>
          <p>spin@weely.ch</p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
